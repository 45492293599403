body:has(.drawer-backdrop.show) {
    overflow: hidden;
}

.ogle {
    .drawer-backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        z-index: 10;
        visibility: hidden;
        transition: visibility 560ms cubic-bezier(0.77, 0, 0.18, 1), background 560ms cubic-bezier(0.77, 0, 0.18, 1);

        & .drawer {
            right: -20.5rem;
            top: 0;
            height: 100%;
            max-height: 100vh;
            transition: right 560ms cubic-bezier(0.77, 0, 0.18, 1);
        }
    }

    .drawer-backdrop.show {
        background: rgba(52, 64, 84, 0.60);
        visibility: visible;
        transition: visibility 560ms cubic-bezier(0.77, 0, 0.18, 1);
        & .drawer {
            right: 0;
            transition: right 560ms cubic-bezier(0.77, 0, 0.18, 1);
        }

    }

    .drawer {
        width: 20.5rem;
        transition: right 560ms cubic-bezier(0.77, 0, 0.18, 1);
    }
    
    .border-top-1px-solid {
        border-top-width: 1px;
        border-top-style: solid;
    }

    .user-dropdown {
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.05), 0px 12px 16px -4px rgba(16, 24, 40, 0.10);
        min-width: 15rem;
    }

    .z-index-1 {
        z-index: 1;
    }

    @media only screen and (min-width: 768px) {
        .drawer-backdrop .drawer {
            right: -24.5rem;
        }

        .drawer-backdrop.show .drawer {
            width: 24.5rem;
        }
    }
}