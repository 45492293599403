@import './flex.scss';

.visibility-hidden {visibility: hidden;}
.display-none {display: none;}
.position-fixed {position: fixed;}
.position-static {position: static;}
.display-block {display: block;}
.margin-0-auto {margin: 0 auto;}
.display-grid {display: grid;}
.grid-cols-1 {grid-template-columns: repeat(1, minmax(0, 1fr));}
.grid-cols-2 {grid-template-columns: repeat(2, minmax(0, 1fr));}
.grid-cols-3 {grid-template-columns: repeat(3, minmax(0, 1fr));}
.grid-cols-6 {grid-template-columns: repeat(6, minmax(0, 1fr))};
.col-span-1	{grid-column: span 1 / span 1};
.col-span-2	{grid-column: span 2 / span 2};
.col-span-3	{grid-column: span 3 / span 3};
.col-span-6	{grid-column: span 6 / span 6};
.border-1px-solid {border: 1px solid;}
@media only screen and (min-width: 768px) {
	.mw768 {
		&-visibility-visible {visibility: visible;}
		&-display-none {display: none;}
		&-position-fixed {position: fixed;}
		&-position-static {position: static;}
		&-display-block {display: block;}
		&-margin-0-auto {margin: 0 auto;}
		&-display-grid {display: grid;}
		&-grid-cols-1 {grid-template-columns: repeat(1, minmax(0, 1fr));}
		&-grid-cols-2 {grid-template-columns: repeat(2, minmax(0, 1fr));}
		&-grid-cols-3 {grid-template-columns: repeat(3, minmax(0, 1fr));}
		&-grid-cols-6 {grid-template-columns: repeat(6, minmax(0, 1fr))};
		&-col-span-1 {grid-column: span 1 / span 1};
		&-col-span-2 {grid-column: span 2 / span 2};
		&-col-span-3 {grid-column: span 3 / span 3};
		&-col-span-6 {grid-column: span 6 / span 6};
		&-border-1px-solid {border: 1px solid;};
	}
}
.display-inline {display: inline;}
.display-inline-block {display: inline-block;}
.display-table {display: table;}
.display-table-row {display: table-row;}
.display-table-cell {display: table-cell;}
.display-flex {display: flex;}
.position-relative {position: relative;}
.position-absolute {position: absolute;}
.cursor-pointer {cursor: pointer;}
.pointer-events-none {pointer-events: none;}
.white-space-nowrap {white-space: nowrap;}
.border-bottom-1px-solid {border-bottom: 1px solid;}
.border-bottom-1px-dashed {border-bottom: 1px dashed;}
.border-bottom-1px-dotted {border-bottom: 1px dotted;}
.font-weight-bold {font-weight: bold;}
.font-style-italic {font-style: italic;}
.opacity-0 {opacity: 0;}
.opacity-0dot2 {opacity: 0.2;}
.opacity-0dot4 {opacity: 0.4;}
.opacity-0dot6 {opacity: 0.6;}
.opacity-0dot8 {opacity: 0.8;}
.text-decoration-underline {text-decoration: underline;}
.text-decoration-line-through {text-decoration: line-through;}
.text-align-center {text-align: center;}
.text-align-right {text-align: right;}
.max-width-100percent {max-width: 100%;}
.max-height-100percent {max-height: 100%;}
.max-height-20percent {max-height: 20%;}
.width-100percent {width: 100%;}
.width-80percent {width: 80%;}
.width-25percent {width: 25%;}
.width-20percent {width: 20%;}
.width-50percent {width: 50%;}
.width-40percent {width: 40%;}
.height-400percent {height: 400%;}
.height-200percent {height: 200%;}
.height-100percent {height: 100%;}
.height-80percent {height: 80%;}
.height-60percent {height: 60%;}
.height-50percent {height: 50%;}
.height-40percent {height: 40%;}
.height-25percent {height: 25%;}
.height-20percent {height: 20%;}
.height-100vh {height: 100vh;}
.overflow-wrap-break-word {overflow-wrap: break-word;}
.object-fit-cover {object-fit: cover;}
.object-fit-contain {object-fit: contain;}
.object-fit-fill {object-fit: fill;}
.object-fit-scale-down {object-fit: scale-down;}
.border-radius-50percent {border-radius: 50%;}
.overflow-hidden {overflow: hidden;}
.overflow-auto {overflow: auto;}
.overflow-x-auto {overflow-x: auto;}
.overflow-y-auto {overflow-y: auto;}
.empty-cells-show {empty-cells: show;}
.empty-cells-hide {empty-cells: hide;}
.vertical-align-middle {vertical-align: middle;}
.text-align-end {text-align: end};
.text-overflow-ellipsis {text-overflow: ellipsis;}
