.ogle { .check-out {
  .margin-0-minus1dot25rem {
    margin: 0 -1.25rem;
  }
  .padding-bottom-1dot625rem {
    padding-bottom: 1.625rem;
  }
  .width-6dot375rem {
    width: 6.375rem;
  }
  .width-fit-content {
    width: fit-content;
  }
  .max-width-23dot5rem {
    max-width: 23.5rem;
  }
  @media only screen and (min-width: 768px) {
    .top {
      background: linear-gradient(to bottom, #F9FAFB 0%, #F9FAFB 100%) no-repeat;
      background-size: 100% 18.75rem;
     }

     .card {
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 12px 12px 0px 0px;
     }
  }
}}